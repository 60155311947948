import React, { useState, useEffect, useContext } from "react"
import Markdown from "react-markdown"
import { createFilterOptions } from "@material-ui/lab"
import { markdownNodesFilter } from "~/utils"

import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Hero from "~/components/configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import InfoStrip from "../configurable/InfoStrip"
import Collapsible from "../configurable/Collapsible"
import Icon from "../olc-framework/Icon"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "~/components/olc-framework/FormSelect"

import "./faq.scss"
import "./solar-together-faq.scss"

import Col4 from "../grid/Col4"
import Col11 from "../grid/Col11"
import Col2 from "../grid/Col2"
import Col5 from "../grid/Col5"
import Col8 from "../grid/Col8"
import Col6 from "../grid/Col6"

const faqContent = [
  {
      category:"Battery",
      heading:"Can I charge the battery on a cheap overnight tariff?" ,
      content:"Yes, at survey we will discuss the setup of the system for your needs and explain how the system can work with these tariffs. If you choose to charge the battery up on these off peak tariffs, we can set the battery up to pull charge at those times.",
  }, 
  {
      category:"Battery",
      heading:"How much space do batteries take up?  And where will it be installed?" ,
      content:"They are 200mm high x 500mm deep x 450mm wide for a 2.6Kw battery, and the most common location for a battery is a garage or loft. If not a utility room or a large cupboard space. These are floor standing units",
  }, 
  {
      category:"Battery",
      heading:"What is the battery life and Guarantees?" ,
      content:"There is a 15-year guarantee for performance and the material parts of the battery. They do degrade but they will continue to work after 15 years. ",
  }, 
  {
      category:"Battery",
      heading:"Can we purchase a larger battery than the one suggested?" ,
      content:"You will be given a recommendation based on your registration details. However, you can choose to have a larger battery if you wish. It is best to go with the installers recommendation, then monitor the system over 6-12 months to see if another battery is worthwhile having. ",
  }, 
  {
      category:"Battery",
      heading:"Can the battery be retrofitted?" ,
      content:"Yes, they can be fitted at a later date, they are plug and play so if another is required it is a simple 20 minute job.",
  }, 
  {
      category:"Battery",
      heading:"What is the expected degradation rate per year for the battery?" ,
      content:"None in first 2 years and 3% per annum after that period.",
  }, 
  {
      category:"Battery",
      heading:"What is the maintenance or service required for the battery?" ,
      content:"Much the same as the panels, every 2 years. However, we can monitor the battery remotely and you will have the ability to monitor the battery through an app and web portal. So, if the battery experiences any faults or abnormalities, you will get notified. If this were to happen, we recommend you contact us, and we can help resolve the issue. ",
  }, 
  {
      category:"Battery",
      heading:"Is the battery provided insular or a hybrid?" ,
      content:"If your battery is retrofitted, we offer an AC Couple battery system which comes with a power inverter and charger. This is fitted separate from you existing solar PV system. If you are having the battery installed along with your Solar PV system, we offer a DC Coupled system. This is slightly more efficient and if you haven’t previously had solar it is more cost effective. ",
  }, 
  {
      category:"Battery",
      heading:"Can the battery be used as a backup power supply in the event of a power outage?" ,
      content:"Yes, it can. At an additional cost of £450 we can install a 2-gang socket which is connected to the EPS function of the battery. This will charge certain circuits and appliance you wish to keep live in the event of a power cut. However, this is limited to the charge of the battery at the time of the event. ",
  }, 
  {
      category:"Battery",
      heading:"What is the battery output limit for running appliances?" ,
      content:"With a typical 5.2kw battery it will support up to 5.2Kw of load at any point in time. ",
  }, 
  {
      category:"Battery",
      heading:"Can you add more panels later and if so, would you have to upgrade the battery?" ,
      content:"Adding panels later can depend on your roof size. It would also be advisable to specify this during your survey to ensure the correct inverter and other equipment is fitted to save costs as and when you add more panels. Increasing the battery size is entirely up to you and would dependant on your energy usage.",
  }, 
  {
      category:"Battery",
      heading:"Does the battery come fully charged?" ,
      content:"Typically, the battery will come with around 50% charged after the commissioning process. ",
  }, 
  {
      category:"Battery",
      heading:"Does the battery produce any noise?" ,
      content:"The battery makes no noise. The only equipment that would make a noise is the inverter however this is only a computer like noise. ",
  }, 
  {
      category:"Battery",
      heading:"What is the VAT rate on the battery system?" ,
      content:"If your battery is installed along with your solar PV system VAT would be at 5% and typically, when you are installing a retrofit storage system this would be 20% VAT.",
  }, 
  {
      category:"Battery",
      heading:"Do batteries emit any heat?" ,
      content:"They have an internal cooling mechanism, so heat emitted in minimal. However, they are affected by heat so we would have to ensure the location they are stored have adequate ventilation like a loft.",
  }, 
  {
      category:"Battery",
      heading:"How long does it take to fully charge the battery?" ,
      content:"It really depends how many solar panels are installed and how much sunlight is generated on any given day. A 4kW solar system generating at peak capacity would generate 4KW power. If you have a 5KW battery this would charge within an hour and 15 minutes, providing you weren`t using any of the electricity generated from your solar system in your home. ",
  }, 
  {
      category:"Battery",
      heading:"If I have a battery, do I need to manually switch the electric supply from battery to grid supply?" ,
      content:"No that`s all done automatically. A current transformer will monitor how much energy you`re using from the grid, this will tell the batteries management system how it should react, and it will then give you the power as and when you need it ensuring you don’t export any power you can use for yourself. ",
  }, 
  {
      category:"Battery",
      heading:"If I have a battery included, will I still need to be connected to the National Grid?" ,
      content:"You will need to be connected to the grid for when the solar system isn`t generating electricity. Or your batteries have fully discharged.",
  }, 
  {
      category:"Battery",
      heading:"How will the battery be installed?" ,
      content:"The battery will be installed by electrician in the vicinity of your solar inverter or next to your solar equipment, typically it will be mounted on a frame, and it will have wires interconnecting the additional batteries and then cable connecting it to your inverter.",
  }, 
  {
      category:"Battery",
      heading:"Does the size of the battery include the inverter or is it a separate unit?" ,
      content:"The battery doesn`t include the inverter. The inverter is similar sized but separate and slightly deeper. It`s about 400mm and it will be mounted on the wall. You can see some example photographs on our website and on our social media. Our surveyor will also make sure to have some examples for you when we when we come to carry out your appointment to make sure you can visualise what`s going to be happening.",
  }, 
  {
      category:"Battery",
      heading:"Will having batteries change how much I earn by selling electricity?" ,
      content:"If you have a battery, you will export less electricity to the grid, however the benefits are roughly 7 times higher using the electricity yourself, compared to the money you would get exporting, for example exporting tariffs are around 2-6pence a unit. Compared to saving the free energy in the battery, and not buying it for 16-20pence a unit from suppliers.",
  }, 
  {
      category:"Battery",
      heading:"What app is used to monitor the battery?" ,
      content:"The manufacturer of the battery has their own specific app which connects with the equipment and gives you the monitoring you required. There are video available on our website that provide some demonstrations of the equipment. We can also talk you through this during your survey.",
  }, 
  {
      category:"Battery",
      heading:"Can a battery be programmed to export at times, for example outgoing Octopus tariff?" ,
      content:"Yes, you can programme the battery to export at specific times. On the other hand, you can connect the battery and tell it to export as and when you require it. We can programme it during the installation, or you can remotely programme it after we have carried out the installation to the specific dates and times you require. ",
  }, 
  {
      category:"Battery",
      heading:"How long would the 5KW battery system run a 2KW water heater?" ,
      content:"2.5 hours.",
  }, 
  {
      category:"Battery",
      heading:"How long will the battery retain its charge?" ,
      content:"The battery is designed to hold its charge for as long as possible. For it to continue running and monitoring its functionality it is roughly 0.3/0.4%.",
  }, 
  {
      category:"Battery",
      heading:"Can you charge the battery for use later in the year? E.g. can you save the charge in the battery for winter?" ,
      content:"Yes, you can. Although it wouldn’t be the most cost-effective option as during the winter you would still generate enough electricity to charge you battery. If you were to choose this option, we would need to change the parameters, so no power was exported out of the battery unless you asked it to. However, you may not be getting the most out of the equipment if you made that decision.",
  }, 
  {
      category:"Battery",
      heading:"What company manufactures the batteries?" ,
      content:"FoxEss – links to data sheets are available on our website and will be provided to you when we come and carry out the survey. ",
  }, 
  {
      category:"Battery",
      heading:"Will batteries be useful if we have an emersion heater already?" ,
      content:"Yes, they will still be beneficial as it will increase your energy efficiency.",
  }, 
  {
      category:"Battery",
      heading:"Can you have one or multiple batteries?" ,
      content:"You could add more storage if needed. The batteries come in variable power plusses we can offer 2.6Kw to 5.2kw to 7.8Kw and 10.4Kw. Typically, we find that 5.2Kw batteries are the most appropriate size. ",
  }, 
  {
      category:"Battery",
      heading:"Can the batteries be recycled?" ,
      content:"Yes, the warranty comes with a recycling guarantee. ",
  }, 
  {
      category:"Battery",
      heading:"Does an east and west roof orientation require any additional cost?" ,
      content:"If you are having a two-string system installed, we can use a normal inverter and each side would use a separate performance tracker and strings. If you were having an uneven mixing of panels on either roof, we would recommend you us optimisers in this scenario which involves extra costs. ",
  }, 
  {
      category:"Battery",
      heading:"Is it possible to have panels on flat roofs and outbuildings?" ,
      content:"Yes, it is possible. We would need to assess the infrastructure between the buildings as there would need to be a sufficient electricity supply to the outbuildings. This can be discussed during your survey.",
  }, 
  {
      category:"Installation",
      heading:"If our loft and garage is not a suitable place for a battery can they be places elsewhere?" ,
      content:"Yes, the only location it cannot be installed is externally without an enclosure. ",
  }, 
  {
      category:"Installation",
      heading:"How close does the battery and inverter need to be to the consumer unit?" ,
      content:"They do not need to be close to each other, installation will typically be in your loft garage or in another agreed location, we will then run a suitably sized cable from this location to your consumer unit to connect into it.",
  }, 
  {
      category:"Installation",
      heading:"And are there any environmental requirements for the battery location at all?" ,
      content:"We would want to ensure that the locations were ventilated, that it is not easily accessible, and it is protected from any prying hands e.g. if you have at young children. We would also make sure that it wasn`t installed in a location which would get particularly hot. ",
  }, 
  {
      category:"Installation",
      heading:"Would the scaffolding be up long enough to clean and inspect the roof prior to the installation date?" ,
      content:"Yes, we install the scaffolding the day before the installation. During the installation we take detailed photographs of the roof space and would censure any roof space underneath the panels are cleaned. ",
  }, 
  {
      category:"Installation",
      heading:"Is the deposit refundable if the survey reveals significant changes to what was estimated?" ,
      content:"The £150 deposit is refundable if the installation cannot be done because of your roof orientation etc. However, if we can do the install but you decide you no longer want to go ahead with it the deposit is not refundable. ",
  }, 
  {
      category:"Installation",
      heading:"Does a loft conversion impact ease of installation?" ,
      content:"If there is another appropriate location to store the equipment on the property this should not pose an issue. During the survey they will ensure there is a suitable route from your roof space to wherever the equipment is stored.",
  }, 
  {
      category:"Installation",
      heading:"Does the loft need to be boarded?" ,
      content:"The loft doesn’t need to be boarded. We will attend with crawl boards, and we will remove them once the installation is complete so there will be no disruption.",
  }, 
  {
      category:"Installation",
      heading:"What safety measures are in place during an installation?" ,
      content:"All our installers are trained to carry out work on electricity, solar PV and working at heights. We ensure that any scaffold system erected is certified by the scaffolder prior to installation, and all our installers will carry out a risk assessment and method statement analysis prior to any works being done. We will also do the same at the survey, making sure any unusual risks are highlighted for the installation team. Finally, we will have supervision within the area, we will come and inspect the team, during installation, to ensure they are acting appropriately and in accordance with our documentation, we will also quality audit the installation after work to make sure that everything has been undertaken appropriately",
  }, 
//   {
//       category:"Installation",
//       heading:"Will the installation meet MCS standards?" ,
//       content:"Yes, and you will be issued with MCS certificate post completion to verify this.",
//   }, 
  {
      category:"Installation",
      heading:"Is it possible to delay the installation if there are planned works being done on property prior to install?" ,
      content:"Yes, as long as the install is completed within the schemes timescales at that time.",
  }, 
  {
      category:"Installation",
      heading:"Will the roof tiles be drilled or puncture during the install?" ,
      content:"No, we lift tiles to enable us to fit the roof hook to the batten’s underneath and main support beams. We may need to notch a small etching outside of the underside of the roof tile to enable the brackets and the roof tile to sit closely together to ensure there is no space between the two tiles after the installation. We would replace any accidental damaged tiles.",
  }, 
  {
      category:"Installation",
      heading:"What happens if you want to do a loft extension of future, can the panels be easily moved arranged or removed?" ,
      content:"Yes, they can be easily moved, for either roof repairs or a loft conversion. At that point in time if you contact ourselves, we would quote you to send somebody to site and assist you with temporary removal and reinstallation of the panels and equipment",
  }, 
  {
      category:"Installation",
      heading:"How many panels would fit a 4kW installation?" ,
      content:"Using 375w modules this would equate to around 11 panels",
  }, 
  {
      category:"Installation",
      heading:"Is it necessary to install bird protection?" ,
      content:"It isn’t a requirement but advisable in some areas e.g. coastal properties. ",
  }, 
  {
      category:"Installation",
      heading:"Can I have solar panel installed on the ground?" ,
      content:"Yes. However, you would need planning permission and more intrusive surveys such as assessing the suitability of the ground.",
  }, 
  {
      category:"Installation",
      heading:"Do the panels need to be installed on a south facing roof?" ,
      content:"South is best for maximum efficiency, but it is not a requirement as panels work on the east and west. However, it is recommended not to install panels North facing as this is the least efficient. The desktop survey and the onsite survey will provide you with a full model on what your system is going to produce.",
  }, 
  {
      category:"Installation",
      heading:"What is the minimum distance from the roof ridge or edge of the roof for the solar panels being installed?" ,
      content:"Depending on the structure and topology, it might be less than 200 millimetres but typically less than 300 millimetres.",
  }, 
  {
      category:"Installation",
      heading:"What access is required to the inside of the house and roof?" ,
      content:"We will need access to the inside of the house to enable us to access the location where we will be installing the solar equipment to convert the energy. We will also need to agree with you a route for the cabling from the roof to wherever the solar equipment will be mounted. Typically, we would prefer to go externally, so we would find your distribution board in your house where your electricity comes in. We would then take the cable route externally and then connect through your loft, however if you preferred the route in your garage as an example, we would then bring the DC power from the roof down but we would need access to the property to do that.",
  }, 
  {
      category:"Installation",
      heading:"Do you run a separate cable to a heater if utilising a power diverter?" ,
      content:"No, we don`t need a separate cable as the equipment talks wirelessly. We would install the receiver and the sender locally so that sending would be local to our PV system and the receiver would be local to your heating system. We would need to connect it into the local power supply at your heating system and then the control wiring system to ensure that we are communicating with it and able to tell it what to do.",
  }, 
  {
      category:"Installation",
      heading:"Do we have a say of where the solar panels are placed on the roof?" ,
      content:"Yes, however there are certain guidelines we must follow e.g. cannot go to close to the edge of the property, and maximising where the panels will work at their optimum.",
  }, 
  {
      category:"Installation",
      heading:"When fitted are cables inside walls or under floors visible?" ,
      content:"This is dependent on your property. We will do everything we can to ensure cables are within voids, wall spaces or floor spaces. However, that isn`t always possible so we may need to do some surface mount trunking. In this instance, we would look to go externally and put them in conduit which matches your building to make sure it`s not obtrusive, but we will discuss this during the survey to make sure you’re entirely happy with what we planning.",
  }, 
  {
      category:"Installation",
      heading:"If you came out and did the survey and it was found that the roof condition wasn`t good enough. Would there be time for someone to go ahead and have that replaced or updated so that it would be suitable?" ,
      content:"It`s not typically built into our 4–5-day installation window, however, we could discuss that with you and possibly arrange for the scaffold to be installed a slightly longer period of time. ",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"How often do panels need cleaning? Is scaffolding needed to clean or install panels?" ,
      content:"Yes, scaffolding is needed to install the panels and it is included in the price. Typically, you don’t need scaffolding to clean the panels if it a 2-story building and we recommend cleaning every two years but that is dependent on your area.  A local window cleaner can be used, if they have a telescopic pole using clean ironized water.",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"What maintenance or servicing do the solar panels require?" ,
      content:"No maintenance is required. However, we would recommend that every 2 years an inspection is carried out to ensure everything is operating as you would expect. This will include electrical test, a visual inspection and cleaning of the solar panels. ",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"How do people replace faulty panels?" ,
      content:"If the fault is within the warranty the supplier of the equipment will cover the cost of replacing faulty panels. You can contact us or directly contacting the provider of the equipment.",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"How much would the 2-year maintenance inspection cost?" ,
      content:"Depends on the regularity of the contract but typically we charge around £495. ",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Can debris e.g. Leaves, gather under the solar panels? Is this part of the cleaning and maintenance?" ,
      content:"This is not a common issue because of the proximity of the panels to the roof tiles. If leaves were to gather under the panels this can be discussed during the maintenance visit.",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"If something were to break within warranty would there be any additional costs?" ,
      content:"No. ",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"How can we monitor our system?" ,
      content:"We provide a WIFI enabled device connected to your inverter. You can then download an app which will enable you to monitor your systems performance and warn you of any unusual activity.",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Is there any signal to alarm someone if their solar inverter needs to be replaced?" ,
      content:"Yes, this will come up on the portal. You would receive an alarm code, then you`d be able to cross reference this alarm code with the manual that comes with the equipment for information. However, we would recommend you call us if anything was to occur, and we would advise you with the best way to proceed. ",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"How does monitoring occur if someone doesn’t have wifi?" ,
      content:"You would be able to use the application but only when you are home, if it’s not able to communicate with Wi-Fi you would not be able to monitor your system while you’re not at the property, although it would work when you’re within the proximity of your inverter",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Will moss grow on the solar panels at all?" ,
      content:"Not typically, however when cleaning the system, it would be important to make sure that whoever is cleaning your panels are capturing any moss present. ",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Would anything need to be reset if there is a power outage?" ,
      content:"Generally, No. Everything should come back on as normal. However, depending on the reason for the outage it may be that a fault has been presented to your property and the readings have told the equipment they need to protect themselves. There`s a protective device called an RCD in place which will trip in instances of certain fault scenarios, so it may be that the RCD needs resetting. In these instances, we would advise you verbally over the phone what needs to be done and you should be able to reset it yourself",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Explain more about the EPS, can I connect my broadband router to it and some other computer equipment, so it stays on during a power cut? Will it reduce the spiking on the power lines when the power cut ends?" ,
      content:"Yes, you can but be aware that if the power cuts not just localised to your property and it’s localised to the area it could mean that your data connectivity is still impacted. However, if it is simply a power outage at your property, yes you could connect your modem and your computer equipment to it, and it would support you from the duration of the battery charge.",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Can others service the system e.g. electrician?" ,
      content:"Yes, they can. We recommend that the qualified electrician has PV solar installation experience, and you should check this before they carry out the work.",
  }, 
  {
      category:"Operation and Maintenance",
      heading:"Will it cost me to have the efficiency of the system checked?" ,
      content:"On an annual/ 2-year inspection basis yes there would be a maintenance charge. You can also check the system efficiency on the portals to compare the system to our forecasted data at any time.",
  }, 
  {
      category:"Technical Questions",
      heading:"How do solar panels cope in high winds?" ,
      content:"We will carry out a structural engineering report on your property prior to the installation. We will analyse the wind strength in your location as well as your roof structure. This will specify to us how many brackets are needed to fit the panels securely in extreme weather conditions.  ",
  }, 
  {
      category:"Technical Questions",
      heading:"I have an old-style fuse box. Would it be better to have this replaced with a breaker box consumer unit prior to the installation?" ,
      content:"This is not a requirement. When we carry out the survey, we will inform you of anything that needs to be addressed prior to the installation. ",
  }, 
  {
      category:"Technical Questions",
      heading:"What’s the best way to heat your home using solar energy?" ,
      content:"Depending on your fuel arrangement there are a couple options, we can install a power diverter which will talk with an electric storage boiler if you have one fitted, but this does not work when battery storage is installed as the suplus energy will go to this. A battery will save you far more than a power diverter. Additionally, an air source heat pump can be installed quite neatly to work in conjunction with solar PV IF you have an electrical heating element. If you have only gas, then solar PV wouldn’t provide benefit for that arrangement",
  }, 
  {
      category:"Technical Questions",
      heading:"If someone has voltage that is quite high e.g. 250 volts, would that be a problem?" ,
      content:"This shouldn`t be a problem; the equipment is designed to ensure that we match voltages. All equipment these days are installed to international standards so we can deal with varying voltages we just need to make sure when we install that we set the correct parameters.",
  }, 
  {
      category:"Technical Questions",
      heading:"Does each solar panel work independently from each other?" ,
      content:"If one fails do, they all fail?  This depends on the system you have in place. For normal systems without optimisers on the panels, if one panel has a fault this will affect the performance of entire string of panels. If your system has optimisers this will electronically divert the power around the damaged panel so this would isolate the impact to that one damaged panel.",
  }, 
  {
      category:"Technical Questions",
      heading:"What is the size of the solar panels?" ,
      content:"1mx1.8m",
  }, 
  {
      category:"Technical Questions",
      heading:"Would I benefit from changing my heating from gas to electric?" ,
      content:"In the long term it would be to your benefit as gas prices are expected to rise in the coming years but for now it is more cost effective to use gas. ",
  }, 
  {
      category:"Technical Questions",
      heading:"What is the typical weight of the solar panel?" ,
      content:"Typically, panel weigh between 18Kg and 20Kg. Ours are 19.3Kg.",
  }, 
  {
      category:"Technical Questions",
      heading:"What is the life span of a solar inverter?" ,
      content:"Warranty is 10+ years so any failure will be covered by the supplier. Typically, the average inverter should last 13-14 years.",
  }, 
  {
      category:"Technical Questions",
      heading:"What is the effect of bird dropping on the efficiency of the panels?" ,
      content:"The impact on performance varies depending on the amount of bird droppings. A low coverage can impact the performance about 3-4% on average. If this was to incur a lot, we would recommend you have them cleaned,",
  }, 
  {
      category:"Technical Questions",
      heading:"Would the solar panels affect the building insurance?" ,
      content:"It should not affect your building insurance, but you should notify your insurers of the system. ",
  }, 
  {
      category:"Technical Questions",
      heading:"Do the panels get hot?" ,
      content:"Yes, but they do not become a heat risk. With direct sunlight they will never get much hotter than 20-30 degrees C. ",
  }, 
  {
      category:"Technical Questions",
      heading:"How much wattage can each panel collect?" ,
      content:"375 Watts per module",
  }, 
  {
      category:"Technical Questions",
      heading:"Are the solar panels vulnerable to lightning strikes?" ,
      content:"This is not a common occurrence; however we do connect the solar panels to your building earthing system so it protects your property, making sure that any energy generated through a lightning strike is diverted to ground and make sure that there that there is minimal risk.",
  }, 
  {
      category:"Technical Questions",
      heading:"Do we know what the difference is between the amount of power generated between the summer and winter?" ,
      content:"Yes, you`re able to monitor the energy you generate on a daily basis if you wish, this will enable you to analyse periods of time whether that be monthly, quarterly or annually. So, you can review how much you`ve been generating in winter months versus summer months or how much energy you`re using when you`re home to compare to when you`re not.",
  }, 
  {
      category:"Technical Questions",
      heading:"What is the conversion efficiency of the inverter?" ,
      content:"Solar PV inverter on its own is in the region of 98% to 99%. If it`s a solar PV inverter with a DC coupled system, the conversion ratio gets slightly more complex because the power direction can go three ways before it is used. So, it may start at 98% or 99% if you`re using it straight away, however, if it goes to the battery and then goes to your household it would be closer to 96/97% because of the conversion cycle of the battery.",
  }, 
  {
      category:"Technical Questions",
      heading:"Can I charge my electric car with solar?" ,
      content:"Yes, if plugged in when sun is shining and/or by using the battery storage for after dark",
  }, 
  {
      category:"Technical Questions",
      heading:"Is there warranty for the panels and batteries?" ,
      content:"There is warranty for the battery storage, inverter, solar panels, and the mounting structure.",
  }, 
  {
      category:"Technical Questions",
      heading:"Will it be ready for connection to our air source heat pump?" ,
      content:"Yes. Your air source heat pump will be connected to you consumer unit and your PV system will be connected to the same circuit meaning if your Air source heat pump demands power the solar panels or battery can help to provide that. ",
  }, 
  {
      category:"Technical Questions",
      heading:"Should the panels be insured separately?" ,
      content:"This is entirely up to you, we provide an insurance scheme on the day of installation, so the first 12 months are insured as part of our process, and you get ongoing warranties and provisions from us as the installer as well as the manufacturers. If you would like to take out additional insurance being long term and then that`s something we can discuss.",
  }, 
  {
      category:"Technical Questions",
      heading:"Is there any danger of fire caused by the equipment?" ,
      content:"It is electrical equipment so there is always a risk. However, we install protection to ensure that that risk is kept to a very low level and the protection equipment as well as the internal protection of the device, mitigates the risk of fire and disconnects the equipment from any source that may enable a fire should it occur.",
  }, 
  {
      category:"Technical Questions",
      heading:"There used to be a limit to the number of panels fitted. Is that no longer the case?" ,
      content:"There was never a specific limit but the benefits you will receive varied significantly depending on the number of panels you have. If you were to have more than 4kW of solar panels (16 kilowatt amps per phase) we would want to speak with your DNO and verify they are ok for that to be installed. Provide that’s within a reasonable amount, the DNO generally would have any concerns or cost implications.",
  }, 
  {
      category:"Technical Questions",
      heading:"Do we require a smart meter?" ,
      content:"No, we will install a meter that monitors your use and export generation and export. You potentially need one if you would like to apply for the Smart Export Guarantee which means any power that leaves your property you will get paid for by your energy supplier.",
  }, 
  {
      category:"Technical Questions",
      heading:"Do panels need direct sunlight or just daylight?" ,
      content:"Direct sunlight isn’t required for solar panels to generate.",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"What is the Smart Export Guarantee? Does it matter who the energy supplier is?" ,
      content:"It is a government incentivised scheme for energy companies who offer anyone with solar panels installed the ability to get paid for the power that is exported to the grid. It is typically 3-5p per Kilowatt exported. Please always check the T&C from your supplier. You are also not obliged to use your current energy supplier for you Smart Export Guarantee, but some energy suppliers may offer special prices if you import energy from them also. ",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"Do we need to start talking to our energy supplier prior to installation about the Feed in Tariff?" ,
      content:"Feed in tariffs are no longer available to anyone as of March 2019 and you don’t need to notify your supplier prior to the install. Although we would recommend notifying your supplier after the installation as they my estimate your monthly usage and over charge you if they are not aware you have solar. ",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"What happen to excess energy?" ,
      content:"If you don’t have storage any excess energy will be exported to the grid which you will be paid for if you have registered for the Smart Export Guarantee. If you do have storage the excess energy will be stored and once the storage if full any excess will be exported.",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"If I already receive a feed in tariff payment, can I add additional panels to receive more benefist?" ,
      content:"This will not be possible as the panels you’ve registered with your feed in tariff are registered with Ofgem and your feed in tariff provider. You won’t be able to increase the amount of power you have already registered with them.",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"Will Solar Together automatically sign me up to sell my electricity?" ,
      content:"No they don’t, your electricity and your supply is completely separate from what is part of the Solar Together Scheme.",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"Do all energy providers accept electricity generated by a household?" ,
      content:"No. Only energy providers that are registered and agreed with the Smart Export Guarantee will accept power exported. ",
  }, 
  {
      category:"SEG Payments/FiT Tarif/Green Homes Grant",
      heading:"Can I get a battery installed if I already receive FIT payments?" ,
      content:"Yes you could have a battery if you receive FIT payment already, however you would need explain this to your FIT licensee. We will give you a schematic to present to them so they can verify they’re happy with the arrangement. ",
  }, 
  {
      category:"Health and Safety",
      heading:"What measures are taken to ensure the safety of homeowners in regards to COVID-19?" ,
      content:"You can find our COVID-19 guidelines here. COVID-19 | Green Energy Together | Solar Panel Installer (get-uk.com) These will give you the appropriate risk assessments and method statements about our methodology. We operate under a question-answer system regarding COVID-19, so at the time of booking your survey and installation we will ask you a series of questions to make sure that you are happy for us to work on your property. During installation we would generally ask that you are not within the vicinity of the engineer, so we would ask you to remain in your living room or kitchen area and notify us if you`re looking to come and inspect anything that we`re doing, so we can step away and to give both parties space. Engineers will also wear full pp gloves, glasses, masks and safety equipment and will wipe down any areas touched during the installation on your property with antibacterial wipes.",
  }, 
//   {
//       category:"DNO",
//       heading:"Can you explain a bit more about what the DNO document and MCS report are?" ,
//       content:"Your DNO is the Distribution Network Operator, they are the company which manages the electricity that is distributed through it throughout the UK localised your area. We would carry out a DNO application post installation of your system, this will be a G98 certificate that informs the DNO that we`ve installed solar equipment on your property and that it will generate X amount of energy per annum and will export X amount of energy per hour. if you`re having a larger solar system above 4 kilowatts or 16 amps per phase, we would need a G99 application which will be a slightly more complex approach and may take a little longer from survey. MCS is the Micro Generation Certification scheme which is used as an ongoing quality standard measure like Trustmark. It requires that we are joined with this scheme, and they monitor our installation quality annually and verify that we`re installing to their standards and ensure that we`re continuing to be compliant.",
//   }, 
  {
      category:"DNO",
      heading:"Is there a cost for DNO approval?" ,
      content:"Typically, not if you`re having less than 4KW installed and generally not if you`re having more than 4KW installed. However, if you have more than 4KW or more accurately 16 amps of generation per phase installed we would need to speak with your DNO and verify that they are happy for the connect arrangement, and that they wouldn`t need any increased network protection to allow that. Payment/Additional Costs",
  }, 
  {
      category:"Payment/Additional Costs",
      heading:"Do you offer financing for panels through the scheme?" ,
      content:"No, it is payable as one lump sum after the install is complete.",
  }, 
  {
      category:"Payment/Additional Costs",
      heading:"Who owns the solar panels with solar together?" ,
      content:"Solar panels are owned by the customer once the system has been paid for and the install is complete.",
  }, 
  {
      category:"Payment/Additional Costs",
      heading:"How much will an Emergency Power Supply (EPS) cost?" ,
      content:"Approximately £450, an inspection at the property will be carried out and agree the location and earthing requirements discussed to ensure these can be met.",
  }, 
  {
      category:"Payment/Additional Costs",
      heading:"What are the costs of optimisers?" ,
      content:"Partial optimisation of the system is £50 per panel and if you chose to have the entire system optimised it is £30 per panel - the costs will be in the pack you will receive. ",
  }, 
  {
      category:"Payment/Additional Costs",
      heading:"Can the final invoice be paid by credit card?" ,
      content:"Yes.",
  }, 
  {
      category:"Payment/Additional Costs",
      heading:"Is there a charge for paying through credit cards?" ,
      content:"No, there is no additional charges. ",
  }, 
  {
      category:"Miscellaneous",
      heading:"Will I need planning permission for a listed building or conservation area?" ,
      content:"This is dependent on your council so please contact your planning authority directly.",
  }, 
  {
      category:"Miscellaneous",
      heading:"Are the installation crews employees or are these subcontractors?" ,
      content:"They are all employees with us, they operate under our business terms and conditions and under our Health and Safety policies.",
  }, 
  {
      category:"Miscellaneous",
      heading:"What happens when you move to a new house? Do solar panels add value, or can you move the panels to the new property?" ,
      content:"You can take the panels with you. However, it is often more cost effective to sell the panels with the property. A study by Savills found that solar panels on properties tend to increase the value of the property. ",
  }, 
  {
      category:"Miscellaneous",
      heading:"Will the firm be available 24/7 if there were any problems?" ,
      content:"No unfortunately not. We are available from 8:00 AM to 6:00 PM Monday to Friday. We do have an answer phone service and we do have online chat which do get through to us. So, if you needed to contact us out of hours will do our best to come back to you as quickly as possible. We do suggest that if you were to have an issue with your system or your grid supplier the best person to contact will be your district network operator, which your energy provider would have given you details for.",
  }, 
  {
      category:"Miscellaneous",
      heading:"Can my neighbour still register for solar together?" ,
      content:"You can still register. The registration for this scheme has closed however, we run these schemes in the spring, summer, and autumn but we can’t always guarantee what areas are going to be in. Regularly check the website for updates. If you have missed registration, then contact us direct to see what you do for you. If you have missed registration, then contact us direct to see what you do for you."
  },
]


const FAQPage = ({  }) => {

  //this makes it so the customer type is set always as what it needs to be on that page

    const { customerType, setCustomerType } = useContext(CustomerTypeContext);

    const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
    const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
    const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);
    
  
  //END this makes it so the customer type is set always as what it needs to be on that page

  // START button filter

  const [category, setCategory] = useState('All');

  const [keyword, setKeyword] = useState('');

  const filteredFaqs = React.useMemo(() => {


    if (keyword != "") {
      //console.log('selected searchbar');
      if (keyword === "") {
        return faqContent;
      }
      return faqContent.filter(faq => {
        return Object.values(faq).some((text) => text.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
      });

    } else if (category != "") {
        //console.log('selected category');
        if (category === "All") {
          return faqContent;
        }
        return faqContent.filter(faq => faq.category.includes(category));
      } 
      return faqContent;

  }, [category, keyword]);

  // END buttons filter


  return (
    <div className="faq-page">
      <Hero imageUrl="/images/banner_new_1.png" compact>
        <Heading level={1} underlined>
        Support and FAQs
        </Heading>
      </Hero>
      <div style={{backgroundColor:"#e5e5e5"}}>
        <div className="container container--column">
          <div className="row  contactRow" >
            <Col5>
              <div className="numbersBlock " >
                <div className="numbersBlockFirstItem">              
                <div className="numbersBlockItemTitle">
                <strong >
                  Domestic customers
                </strong><br/>
                </div>
                <div>
                  <a
                    href="tel:02039954422"
                    style={{

                    }}
                  >
                    <Icon alias="phone" />
                    ⠀020 3995 4422
                  </a>
                </div> 
                <div>
                  <a
                    href="mailto:domestic.enquiries@get-uk.com"
                    style={{

                    }}
                  >
                    <Icon alias="email" />
                    ⠀domestic.enquiries@get-uk.com
                  </a>
                </div> 
                <div>
                  <a
                    href="mailto:domestic.quotes@get-uk.com"
                    style={{

                    }}
                  >
                    <Icon alias="email" />
                    ⠀domestic.quotes@get-uk.com
                  </a>
                </div> 
              </div>
            </div>
          </Col5>
            <Col5>  
              <div>
                <div className="numbersBlockItem">              
              <div className="numbersBlockItemTitle">
              <strong>
                Solar Together
              </strong>
              </div>
              <div>
                <a
                  href="tel:02039898987"
                  style={{

                  }}
                >
                  <Icon alias="phone" />
                  ⠀London: 020 3989 8987
                </a>
              </div> 
              <div>
                <a
                  href="tel:02038669896"
                  style={{

                  }}
                >
                  <Icon alias="phone" />
                  ⠀Devon: 020 3866 9896
                </a>
              </div> 
              <div>
                <a
                  href="mailto:solar.together@get-uk.com"
                  style={{

                  }}
                >
                  <Icon alias="email" />
                  ⠀solar.together@get-uk.com
                </a>
              </div> 
            </div>
              </div>                   
          </Col5>
        </div>
        </div>
      </div>
      <Block>
        <div className="container u-layout--indent">
          <div className="row" style={{marginBottom:"40px"}}>
            <Col11>
              <p>
              We're here to help.  Find the answer to your most common questions below, covering every detail of your solar installation. To go back to the Solar Together main page just <a href="/solar-together" style={{color:"#70b33b", fontWeight:"bold"}}>click here</a>.
              </p>
            </Col11>
          </div>
        </div>
        <div className="container u-layout--indent"  style={{marginBottom:"70px"}}>
          <div className="row faq_page"  >
              <Col6>
                  <FormSelect
                    id="categorySelecter"
                    name="department"
                    label="Department"
                    options={["All","Battery","Installation","Operation and Maintenance","Technical Questions","SEG Payments/FiT Tarif/Green Homes Grant","Health and Safety","DNO","Installation","Payment/Additional Costs","Miscellaneous"  ]}
                    onChange={e => 
                      
                      setCategory(e.target.value)}
                  />
              </Col6>
              <Col6>
                <FormInput
                  id="searchbar"
                  name="keyword"
                  label="Search by keyword"
                  placeholder="Search by keyword"
                  onChange={e =>
                    setKeyword(e.currentTarget.value)}
                />
              </Col6>
            </div>
        </div>
        <div className="container u-layout--squidge container--column">
            {filteredFaqs.map(singleFAQ => 
                <Collapsible
                category={singleFAQ.category}                   
                heading={singleFAQ.heading}
                content={singleFAQ.content}
                />
            )}
        </div>
      </Block>
    </div>
  )
}

export default FAQPage
